import Vue from 'vue'
import Vuex, {createLogger} from 'vuex'
import broker, { NAME as BROKER } from '@/views/broker/store';
import claims, { NAME as CLAIMS } from '@/views/claims/store';
import messageLog, { NAME as MESSAGE_LOG } from '@/views/messageLog/store';
import subscribe, { NAME as SUBSCRIBE } from '@/views/subscribe/store';
import { createStore } from 'vuex-extensions'

Vue.use(Vuex)

// RootState
export interface IRootState {
  appName: string;
  appVersion: string;
  gitHash: string;
}

export const RootState: IRootState = {
  appName: process.env.VUE_APP_NAME || '',
  appVersion: process.env.VUE_APP_PACKAGE_VERSION || '',
  gitHash: process.env.VUE_APP_GIT_HASH || '',
};

export default createStore(Vuex.Store, {
  state: RootState,
  mutations: {
  },
  actions: {
  },
  getters: {
    getAppVersion: (state) => {
      return state.appVersion
    },
    getAppName: (state) => {
      return state.appName
    },
    getGitHash: (state) => {
      return state.gitHash
    }
  },
  modules: {
    // Views
    [BROKER]: broker,
    [CLAIMS]: claims,
    [SUBSCRIBE]: subscribe,
    [MESSAGE_LOG]: messageLog
  },
  plugins: [
    createLogger({
      logActions: true,
      logMutations: true
    })
  ]
})

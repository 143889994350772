import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Broker from "@/views/broker/Broker.vue";
import Claims from "@/views/claims/Claims.vue";
import MessageLog from "@/views/messageLog/MessageLog.vue";
import Subscribe from "@/views/subscribe/Subscribe.vue";
import Publish from "@/views/publish/Publish.vue";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'broker',
    component: Broker
  },
  {
    path: '/broker',
    name: 'Broker',
    component: Broker
  },
  {
    path: '/claims',
    name: 'Claims',
    component: Claims
  },
  {
    path: '/subscribe',
    name: 'Subscribe',
    component: Subscribe
  },
  {
    path: '/publish',
    name: 'Publish',
    component: Publish
  },
  {
    path: '/messageLog',
    name: 'MessageLog',
    component: MessageLog
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

import { MutationTree } from 'vuex';
import { IBrokerState } from './state';
import {SmokerConnectionDto} from "@/domain/SmokerConnectionDto";

export const SET_CONNECTION = 'setConnection';
export const mutations: MutationTree<IBrokerState> = {
    [SET_CONNECTION](state, smokerConnection: SmokerConnectionDto) {
        state.smokerConnection = smokerConnection;
    }
};

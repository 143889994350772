









import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CopyToClipboardValue extends Vue {
  @Prop() private value!: string;
  @Prop() private ellipsed!: boolean;

  private copyValueToClipboard() {
    navigator.clipboard.writeText(this.value);
    this.$buefy.toast.open({
      message: 'Value copied to clipboard',
      type: 'is-success'
    })
  }
}

import { MutationTree } from 'vuex';
import { IMessageLogState } from './state';
import Vue from "vue";
import {MqttMessageDto} from "@/domain/MqttMessageDto";

export const ADD_MESSAGE = 'addMessage';
export const REMOVE_ALL_MESSAGES = 'removeAllMessages';

export const mutations: MutationTree<IMessageLogState> = {
    [ADD_MESSAGE](state, message: MqttMessageDto) {
        Vue.set(state.receivedMessages, message.receivedOn.getMilliseconds(), message);
        state.receivedMessages = { ...state.receivedMessages };
    },
    [REMOVE_ALL_MESSAGES](state) {
        state.receivedMessages = { }
    },
};

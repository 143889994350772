import { MutationTree } from 'vuex';
import { IClaimsState } from './state';
import { Restriction } from "smoking-mqttjs";
import Vue from "vue";

export const ADD_RESTRICTION = 'addRestriction';
export const REMOVE_RESTRICTION = 'removeRestriction';

export const mutations: MutationTree<IClaimsState> = {
    [ADD_RESTRICTION](state, restriction: Restriction) {
        console.log("ADD: " + restriction.topicName)
        Vue.set(state.claimedRestrictions, restriction.topicName, restriction);
        state.claimedRestrictions = { ...state.claimedRestrictions };
    },
    [REMOVE_RESTRICTION](state, topicName: string) {
        console.log("REMOVE: " + topicName)
        Vue.delete(state.claimedRestrictions, topicName);
        state.claimedRestrictions = { ...state.claimedRestrictions };
    }
};

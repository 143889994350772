














































import {Component, Vue, Prop} from 'vue-property-decorator';
import {PermissionDto} from "@/domain/PermissionDto";
import {MqttActivityType} from "smoking-mqttjs";

@Component({
  components: {

  },
})

export default class PermissionEditTable extends Vue {
  @Prop() permissions!: Array<PermissionDto>;

  private activityTypes: Array<string>;

  constructor() {
    super()
    this.activityTypes = Object.values(MqttActivityType);
  }

  private addPermission() {
    console.log("Add permission...")
    const permission = { } as PermissionDto;
    this.permissions.push(permission);
  }

  private removePermission(index: number) {
    console.log("Remove permission permissionId:=" + index)
    this.permissions.splice(index, 1);
  }
}

import { Module } from 'vuex';
import { IRootState } from '@/store';

import { actions } from './actions';
import { mutations } from './mutations';
import { getters } from './getters';
import {state, ISubscribeState} from './state';

export const NAME = 'subscribe';
export const store: Module<ISubscribeState, IRootState> = {
    actions,
    getters,
    mutations,
    namespaced: true,
    state
};

export default store;

import { IRootState } from '@/store';
import { ActionTree } from 'vuex';
import { ISubscribeState } from './state';
import { ADD_SUBSCRIPTION, REMOVE_SUBSCRIPTION } from '../store/mutations';

export const SUBSCRIBE = 'subscribe';
export const UNSUBSCRIBE = 'unsubscribe';

export const actions: ActionTree<ISubscribeState, IRootState> = {
    [SUBSCRIBE]({ commit }: any, topicName: string) {
        commit(ADD_SUBSCRIPTION, topicName);
    },

    [UNSUBSCRIBE]({ commit }: any, topicName: string) {
        commit(REMOVE_SUBSCRIPTION, topicName);
    },
};

import { MutationTree } from 'vuex';
import { ISubscribeState } from './state';
import Vue from "vue";

export const ADD_SUBSCRIPTION = 'addSubscription';
export const REMOVE_SUBSCRIPTION = 'removeSubscription';

export const mutations: MutationTree<ISubscribeState> = {
    [ADD_SUBSCRIPTION](state, topicName: string) {
        Vue.set(state.subscriptions, topicName, topicName);
        state.subscriptions = { ...state.subscriptions };
    },
    [REMOVE_SUBSCRIPTION](state, topicName: string) {
        Vue.delete(state.subscriptions, topicName);
        state.subscriptions = { ...state.subscriptions };
    },
};

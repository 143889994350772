
















































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { NAME as NAME_BROKER } from './store';
import { NAME as NAME_MESSAGE_LOG } from '@/views/messageLog/store';
import {CONNECT, DISCONNECT} from "@/views/broker/store/actions";
import {RECEIVE_MESSAGE} from "@/views/messageLog/store/actions";
import {GET_SMOKER_CONNECTION} from "@/views/broker/store/getters";
import {MqttMessageDto} from "@/domain/MqttMessageDto";
import {SmokerConnectionDto} from "@/domain/SmokerConnectionDto";
import CopyClipboardValue from "@/components/CopyClipboardValue.vue";
import {StoreExtended} from "vuex-extensions";
import {IRootState} from "@/store";

const brokerModule = namespace(NAME_BROKER);
const messageLogModule = namespace(NAME_MESSAGE_LOG);
@Component({
  components: {
    CopyClipboardValue
  },
})
export default class Broker extends Vue {
  // store
  @brokerModule.Action(CONNECT) connect!: Function;
  @brokerModule.Action(DISCONNECT) disconnect!: Function;
  @brokerModule.Getter(GET_SMOKER_CONNECTION) smokerConnection!: SmokerConnectionDto;
  @messageLogModule.Action(RECEIVE_MESSAGE) receiveMessage!: Function;

  // local
  private readonly brokerUrl: string;

  constructor() {
    super();
    this.brokerUrl = "";
  }

  private connectBroker() {
    (this.$store as StoreExtended<IRootState>).reset();
    this.connect(this.brokerUrl).then((clientId: string) => {

      // Handle the error Event
      this.smokerConnection.mqttClient.on("error", (error: Error) => {
        this.$buefy.toast.open({
          duration: 10000,
          message: 'Unexpected error: ' + error.message,
          type: 'is-danger'
        })
      });

      // Handle the message event
      this.smokerConnection.mqttClient.on("message", (topic: string, message: Buffer) => {
        const mqttMessage = {
          topic: topic,
          message: message.toString(),
          receivedOn: new Date()
        } as MqttMessageDto

        this.receiveMessage(mqttMessage);
      })
    })
  }

  private disconnectBroker() {
    this.disconnect();
    (this.$store as StoreExtended<IRootState>).reset();
  }
}
































































import {Component, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {NAME as NAME_BROKER} from '@/views/broker/store';
import {GET_SMOKER_CONNECTION} from "@/views/broker/store/getters";
import {SmokerConnectionDto} from "@/domain/SmokerConnectionDto";

const brokerModule = namespace(NAME_BROKER);

@Component({
  components: {
  },
})
export default class App extends Vue {
  // store
  @brokerModule.Getter(GET_SMOKER_CONNECTION) smokerConnection!: SmokerConnectionDto;

}

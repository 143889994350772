




























































































import {Component, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {NAME as NAME_BROKER} from '@/views/broker/store';
import {NAME as NAME_CLAIMS} from '@/views/claims/store';
import {NAME as NAME_SUBSCRIBE} from '@/views/subscribe/store';
import {GET_SMOKER_CONNECTION} from "@/views/broker/store/getters";
import {SmokerConnectionDto} from "@/domain/SmokerConnectionDto";
import {GET_RESTRICTIONS} from "@/views/claims/store/getters";
import {GET_SUBSCRIPTIONS} from "@/views/subscribe/store/getters";
import {SUBSCRIBE, UNSUBSCRIBE} from "@/views/subscribe/store/actions";
import {Restriction} from "smoking-mqttjs";
import CopyClipboardValue from "@/components/CopyClipboardValue.vue";

const brokerModule = namespace(NAME_BROKER);
const claimsModule = namespace(NAME_CLAIMS);
const subscribeModule = namespace(NAME_SUBSCRIBE);

@Component({
  components: {
    CopyClipboardValue
  },
})

export default class Subscribe extends Vue {
  // store
  @brokerModule.Getter(GET_SMOKER_CONNECTION) smokerConnection!: SmokerConnectionDto;
  @claimsModule.Getter(GET_RESTRICTIONS) claimedRestrictions!: Array<Restriction>;
  @subscribeModule.Getter(GET_SUBSCRIPTIONS) subscriptions!: Record<string, any>;
  @subscribeModule.Action(SUBSCRIBE) subscribe!: Function;
  @subscribeModule.Action(UNSUBSCRIBE) unsubscribe!: Function;


  private customTopic = "";

  constructor() {
    super();
  }


  private subscribeTopic(topic: string) {
    console.log("Subscribe to topic:=" + topic)
    this.smokerConnection.mqttClient.subscribe(topic).then((grants) => {
      this.subscribe(topic);
      this.customTopic = "";
    }).catch(reason => {
      this.$buefy.toast.open({
        duration: 10000,
        message: 'Could not subscribe. Reason: ' + reason,
        type: 'is-danger'
      })
    });
  }

  private unsubscribeTopic(topic: string) {
    this.smokerConnection.mqttClient.unsubscribe(topic).then(() => {
      this.unsubscribe(topic);
    }).catch(reason => {
      this.$buefy.toast.open({
        duration: 10000,
        message: 'Could not unsubscribe. Reason: ' + reason,
        type: 'is-danger'
      })
    });
  }

  private switchTriggered(value: boolean, topic: string) {
    // Subscribe
    if (value) {
      this.subscribeTopic(topic);
    }

    // Unsubscribe
    else {
      this.unsubscribeTopic(topic);
    }
  }

  private isSubscribed(topicName: string): boolean {
    return this.subscriptions[topicName] != null;
  }
}

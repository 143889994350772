import { IRootState } from '@/store';
import { ActionTree } from 'vuex';
import { IMessageLogState } from './state';
import { ADD_MESSAGE, REMOVE_ALL_MESSAGES } from '../store/mutations';
import {MqttMessageDto} from "@/domain/MqttMessageDto";

export const RECEIVE_MESSAGE = 'receiveMessage';
export const CLEAR_MESSAGES = 'clearMessages';

export const actions: ActionTree<IMessageLogState, IRootState> = {
    [RECEIVE_MESSAGE]({ commit }: any, mqttMessage: MqttMessageDto) {
        commit(ADD_MESSAGE, mqttMessage);
    },

    [CLEAR_MESSAGES]({ commit }: any) {
        commit(REMOVE_ALL_MESSAGES);
    },
};

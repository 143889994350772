
























































import {Component, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {NAME as NAME_BROKER} from '@/views/broker/store';
import {NAME as NAME_CLAIMS} from '@/views/claims/store';
import {GET_SMOKER_CONNECTION} from "@/views/broker/store/getters";
import {SmokerConnectionDto} from "@/domain/SmokerConnectionDto";
import {GET_RESTRICTIONS} from "@/views/claims/store/getters";
import {Restriction} from "smoking-mqttjs";
import CopyClipboardValue from "@/components/CopyClipboardValue.vue";

const brokerModule = namespace(NAME_BROKER);
const claimsModule = namespace(NAME_CLAIMS);

@Component({
  components: {
    CopyClipboardValue
  },
})

export default class Publish extends Vue {
  // store
  @brokerModule.Getter(GET_SMOKER_CONNECTION) smokerConnection!: SmokerConnectionDto;
  @claimsModule.Getter(GET_RESTRICTIONS) claimedRestrictions!: Array<Restriction>;

  private MODE_CUSTOM = "CUSTOM";
  private MODE_CLAIMED = "CLAIMED";

  private publishMode = this.MODE_CUSTOM;
  private customTopic = "";
  private publishMessage = "";

  constructor() {
    super();
  }

  private publishTopic(topic: string, message: string) {
    console.log("Publish to topic:=" + topic + ", message:=" + message)
    this.smokerConnection.mqttClient.publish(topic.trim(), message, { qos: 1 }).then((packet) => {
      this.customTopic = "";
      this.publishMessage = "";
    }).catch(reason => {
      this.$buefy.toast.open({
        duration: 10000,
        message: 'Could not publish message. Reason: ' + reason,
        type: 'is-danger'
      })
    });
  }
}



































































































































import {Component, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {NAME as NAME_BROKER} from '@/views/broker/store';
import {NAME as NAME_CLAIMS} from '@/views/claims/store';
import {CLAIM, UNCLAIM} from "@/views/claims/store/actions";
import {GET_SMOKER_CONNECTION} from "@/views/broker/store/getters";
import {GET_RESTRICTIONS} from "@/views/claims/store/getters";
import {Claim, Permission, PermissionBuilder, Restriction, RestrictionBuilder, RestrictionType} from "smoking-mqttjs";
import PermissionEditTable from "@/components/PermissionEditTable.vue";
import {RestrictionDto} from "@/domain/RestrictionDto";
import {SmokerConnectionDto} from "@/domain/SmokerConnectionDto";
import CopyClipboardValue from "@/components/CopyClipboardValue.vue";

const brokerModule = namespace(NAME_BROKER);
const claimsModule = namespace(NAME_CLAIMS);

@Component({
  components: {
    PermissionEditTable,
    CopyClipboardValue
  },
})

export default class Claims extends Vue {
  // store
  @brokerModule.Getter(GET_SMOKER_CONNECTION) smokerConnection!: SmokerConnectionDto;
  @claimsModule.Getter(GET_RESTRICTIONS) claimedRestrictions!: Record<string, Restriction>;
  @claimsModule.Action(CLAIM) claim!: Function;
  @claimsModule.Action(UNCLAIM) unclaim!: Function;

  // local
  private isJsonPreviewModalActive = false;
  private restrictionDto: RestrictionDto;
  private restrictionTypes: Array<string>;
  private generatedClaim!: Claim;

  constructor() {
    super();
    this.generatedClaim = new Claim();
    this.restrictionTypes = Object.values(RestrictionType);
    this.restrictionDto = {
      topicName: "",
      restrictionType: RestrictionType.Whitelist,
      permissions: []
    } as RestrictionDto;
  }

  private claimTopic() {
    // build restriction
    const restriction = this.buildRestriction();

    // claim restriction
    this.smokerConnection.mqttClient.claim(restriction).then(() => {
      this.claim(restriction);
    });
  }

  private unclaimTopic(topicName: string) {
    this.smokerConnection.mqttClient.unclaim(topicName).then(() => {
      this.unclaim(topicName);
    });
  }

  private showClaimPreview() {
    this.smokerConnection.mqttClient.createClaim(this.buildRestriction()).then((claim) => {
      this.generatedClaim = claim;
      this.isJsonPreviewModalActive = true;
    });
  }

  private buildRestriction(): Restriction {
    // build permissions
    const perms = new Array<Permission>();
    for (const permDto of this.restrictionDto.permissions) {
      const perm = new PermissionBuilder()
          .withActivity(permDto.activity)
          .withClientId(permDto.clientId)
          .build();
      perms.push(perm);
    }

    // build restriction
    const restriction = new RestrictionBuilder()
        .withTopic(this.restrictionDto.topicName)
        .withRestrictionType(this.restrictionDto.restrictionType)
        .withPermissions(perms)
        .build();

    return restriction;
  }

  private getClaimData(): Array<Restriction> {
    const result = new Array<Restriction>();
    for (const topicName in this.claimedRestrictions) {
      result.push(this.claimedRestrictions[topicName]);
    }
    return result;
  }
}

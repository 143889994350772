




































import {Component, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {NAME as NAME_BROKER} from '@/views/broker/store';
import {NAME as NAME_MESSAGELOG} from '@/views/messageLog/store';
import {NAME as NAME_CLAIMS} from '@/views/claims/store';
import {NAME as NAME_SUBSCRIBE} from '@/views/subscribe/store';
import {GET_SMOKER_CONNECTION} from "@/views/broker/store/getters";
import {GET_RECEIVED_MESSAGES} from "@/views/messageLog/store/getters";
import {MqttMessageDto} from "@/domain/MqttMessageDto";
import {SmokerConnectionDto} from "@/domain/SmokerConnectionDto";
import {GET_RESTRICTIONS} from "@/views/claims/store/getters";
import {CLEAR_MESSAGES} from "@/views/messageLog/store/actions";
import {Restriction} from "smoking-mqttjs";
import {GET_SUBSCRIPTIONS} from "@/views/subscribe/store/getters";

const brokerModule = namespace(NAME_BROKER);
const claimsModule = namespace(NAME_CLAIMS);
const messageLogModule = namespace(NAME_MESSAGELOG);
const subscribeModule = namespace(NAME_SUBSCRIBE);


@Component({
  components: {
  },
})

export default class MessageLog extends Vue {
  // store
  @messageLogModule.Getter(GET_RECEIVED_MESSAGES) receivedMessages!: Array<MqttMessageDto>;
  @messageLogModule.Action(CLEAR_MESSAGES) clearMessages!: Function;
  @brokerModule.Getter(GET_SMOKER_CONNECTION) smokerConnection!: SmokerConnectionDto;
  @claimsModule.Getter(GET_RESTRICTIONS) claimedRestrictions!: Array<Restriction>;
  @subscribeModule.Getter(GET_SUBSCRIPTIONS) subscriptions!: Record<string, string>;

  constructor() {
    super();
  }
}

import { IRootState } from '@/store';
import { ActionTree } from 'vuex';
import { IBrokerState } from './state';
import { SET_CONNECTION } from '../store/mutations';
import {ISmokerMqttClient, SmokerMqttClientBuilder} from "smoking-mqttjs";
import {SmokerConnectionDto} from "@/domain/SmokerConnectionDto";

export const CONNECT = 'connect';
export const DISCONNECT = 'disconnect';

export const actions: ActionTree<IBrokerState, IRootState> = {
    [CONNECT]({ commit, state }, brokerUrl: string): Promise<string> {
        return new Promise( (resolve, reject) => {

            // if a client is already connected perform graceful disconnect
            if (state.smokerConnection != null) {
                state.smokerConnection.mqttClient.disconnect();
                commit(SET_CONNECTION, null);
            }

            const client: ISmokerMqttClient = new SmokerMqttClientBuilder()
                .withBrokerUrl(brokerUrl)
                .build();
            client.connect().then((clientId: string) => {
                const smokerConnection = {
                    mqttClient: client,
                    smokerUrl: brokerUrl,
                    clientId: clientId
                } as SmokerConnectionDto

                commit(SET_CONNECTION, smokerConnection);
                resolve(clientId);
            }).catch((error) => {
                reject(error)
            });
        })
    },

    [DISCONNECT]({ commit, state }) {
        // if a client is already connected perform graceful disconnect
        if (state.smokerConnection?.mqttClient != null) {
            state.smokerConnection.mqttClient.disconnect();
            commit(SET_CONNECTION, null);
        }
    }
};

import { IRootState } from '@/store';
import { ActionTree } from 'vuex';
import { IClaimsState } from './state';
import { ADD_RESTRICTION, REMOVE_RESTRICTION } from '../store/mutations';
import { Restriction } from "smoking-mqttjs";

export const CLAIM = 'claim';
export const UNCLAIM = 'unclaim';

export const actions: ActionTree<IClaimsState, IRootState> = {
    [CLAIM]({ commit }: any, restriction: Restriction) {
        commit(ADD_RESTRICTION, restriction);
    },

    [UNCLAIM]({ commit }: any, restriction: Restriction) {
        commit(REMOVE_RESTRICTION, restriction);
    }
};
